<template>
  <b-modal id="sales-filters" size="md" title="Filtros">
    <b-row>
      <b-col cols="12">
        <b-form novalidate>
          <b-row>
            <b-col cols="6">
              <b-form-group label="De" label-for="date-in">
                <b-form-input
                  id="date-in"
                  v-model="data.date_in"
                  type="date"
                  placeholder="31/12/2019"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Até" label-for="date-out">
                <b-form-input
                  id="date-out"
                  v-model="data.date_out"
                  type="date"
                  placeholder="31/12/2020"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-form-group label="Status" label-for="date-in">
                <b-form-select
                  v-model="data.status"
                  :options="status_list"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <h5>Cliente</h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <b-form-group label="Tipo" label-for="date-in">
                <b-form-select
                  v-model="data.client_type"
                  :options="client_search"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="8">
              <b-form-group label="Cliente" label-for="client_data">
                <b-form-input
                  id="client_data"
                  v-model="data.client_data"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>

    <template v-slot:modal-footer="{ cancel }">
      <b-button variant="danger" @click="cancel()"> Cancelar </b-button>
      <button class="btn-green" @click="onSubmit">Filtrar</button>
    </template>
  </b-modal>
</template>
<script>
import AffiliateService from "@/services/resources/AffiliateService";
const serviceAffiliate = AffiliateService.build();

export default {
  props: {
    value: {
      type: Number,
    },
  },
  data() {
    return {
      data: {
        date_in: null,
        date_out: null,
        status: null,
        client_type: null,
        client_data: null,
      },
      status_list: [
        { value: null, text: "Selecione um status" },
        { value: "paid", text: "Pago" },
        { value: "processing", text: "Processando" },
        { value: "reject", text: "Rejeitado" },
      ],
      client_search: [
        { value: null, text: "Selecione o tipo" },
        { value: "email", text: "E-mail" },
        { value: "name", text: "Nome" },
        { value: "cellphone", text: "Celular" },
      ],
    };
  },
  methods: {
    onSubmit() {
      if (
        (this.data.date_in != null && this.data.date_out != null) ||
        this.data.status != null ||
        (this.data.client_type != null && this.data.client_data != null)
      ) {
        this.$emit("filter", this.data);
        this.$bvModal.hide("sales-filters");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/scss/variables.scss";

.btn-green {
  display: inline-block;
  border-radius: 5px;
  padding: 7px 20px;
  border: 1px $base-color solid;
  width: auto;
  color: #fff;
  background: $base-color;
  cursor: pointer;

  &:hover {
    background: #fff;
    color: $base-color;
    border: 1px $base-color solid;
  }
}
</style>